var ready = (function() {
  // Check for click events on the navbar burger icon
  $('.navbar-burger').click(function() {
    // Toggle the 'is-active' class on both the 'navbar-burger' and the
    // 'navbar-menu'
    $('.navbar-burger').toggleClass('is-active');
    $('.navbar-menu').toggleClass('is-active');
  });

  // Show file name in file input when changed
  $('.file-input').change(function() {
    var filename = $(this).val().split('\\').pop();
    $(this).parent().find(".file-name").html(filename);
  });

  // Check for click events on notification close button
  $('.notification .delete').click(function() {
    // Remove notification
    $('.notification').remove();
  });

  $('#access_request_stage').change(function() {
    const hubManager = document.getElementById('hub_manager');
    const companyName = document.getElementById('company_name');
    const hubManagerInput =
      document.getElementById('access_request_hub_manager');
    const companyNameInput =
      document.getElementById('access_request_company_name');
    const ownerLabel =
      document.querySelector('[for=\'access_request_owner\']');
    const ownerPhoneLabel =
      document.querySelector('[for=\'access_request_owner_phone\']');
    if (this.value == 'Company Director') {
      hubManager.style.display = 'initial';
      hubManagerInput.readOnly = false;
      hubManagerInput.value = '';
      companyName.style.display = 'initial';
      companyNameInput.readOnly = false;
      companyNameInput.value = '';
    } else {
      hubManager.style.display = 'none';
      hubManagerInput.readOnly = true;
      hubManagerInput.value = 'Not applicable';
      companyName.style.display = 'none';
      companyNameInput.readOnly = true;
      companyNameInput.value = 'Not applicable';
    }
    if (this.value == 'Leader') {
      ownerLabel.textContent =
        'What is the full name of your owner?';
      ownerPhoneLabel.textContent =
        'What is the contact number for your owner?';
    } else {
      ownerLabel.textContent =
        'What is the full name of your promoting owner?';
      ownerPhoneLabel.textContent =
        'What is the contact number for your promoting owner?';
    }
  });
});

$(document).on('turbolinks:load', ready);
